.stan {
    /* Modify size here: */
    --size: 50px;
    left: 73.9%;
    top: -5px;
    width: 120px;
    position: relative;
    background: #CB0C9F;
    border-radius: 6px;
  }
  
  .stan:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    width: 3px;
    height: 30px;
    background: #CB0C9F;
    /* border: calc(var(--size) * 0.13) solid transparent;
    border-top-color: #333;
    border-bottom: 0;
    margin-left: calc(var(--size) * 0.13 * -1);
    margin-bottom: calc(var(--size) * 0.13 * -1); */
  }


  .scrollbar::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #D9D9D9;
    border-radius: 10px;
  }
  
  .scrollbar::-webkit-scrollbar
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    width: 10px;
    height: 10px !important;
    background-color: #CB0C9F;
    transition: 0.4s ease-in-out;
    border-radius: 10px !important;
  }
  
  .scrollbar::-webkit-scrollbar-thumb
  {
    background-color: rgba(0,0,0,0.3);
    border-radius: 10px !important;
  }
  