.resourceTimelinePluginCustomCss .fc-toolbar-title:before {
    content: 'Einsatzplanung \00a0 | \00a0 \00a0 \00a0 \00a0';
    font-weight: 400;
}

.resourceTimelinePluginCustomCss .fc-toolbar-title {
    color: #ffffff;
    font-weight: normal;
    font-size: 18px;
}

.resourceTimelinePluginCustomCss .fc-header-toolbar {
    background: #0048B1;
    margin: 0px !important;
    padding: 15px;
    border-radius: 15px 15px 0px 0px;
}

.fc-resourceTimeline-view table.fc-scrollgrid.fc-scrollgrid-liquid {
    border-top: none;
}

.resourceTimelinePluginCustomCss .fc .fc-button-primary:disabled {
    background: #D9D9D9;
    color: #344767;
    border-color: #D9D9D9;
    opacity: 1;
}

.resourceTimelinePluginCustomCss .fc .fc-button-primary {
    background: #ffffff;
    color: #344767;
    border-color: #ffffff;
    opacity: 1;
    font-size: 14px;
}

.resourceTimelinePluginCustomCss .fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc-resourceTimeline-view .fc-datagrid-header .fc-datagrid-expander.fc-datagrid-expander-placeholder {
    display: none;
}

.fc-resourceTimeline-view .fc-scrollgrid-section-header {
    background: #3164AE;
    color: #fff;
}

.fc-resourceTimeline-view .fc-timeline-slot-frame a {
    color: #fff;
    font-weight: normal;
    font-size: 14px;
}

.fc-resourceTimeline-view .input-group .input-group-prepend i {
    font-size: 12px;
}

.fc-resourceTimeline-view .input-group .form-control {
    border: 0px solid !important;
    background-color: transparent;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    padding: 0px;
}

.fc-resourceTimeline-view .input-group .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
}

.fc-resourceTimeline-view .input-group .form-control:focus {
    outline: none !important;
    border: none !important;
    box-shadow: unset;
}

.fc-resourceTimeline-view .fc-timeline-event-harness {
    margin: 7px !important;
}

.fc-resourceTimeline-view .fc-timeline-event-harness .fc-event {
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    height: 24px !important;
    font-weight: 500;
}

.fc-resourceTimeline-view table.fc-scrollgrid {
    border-top: none;
}

.fc-resourceTimeline-view .fc-datagrid-body {
    font-size: 12px;
}

.fc-resourceTimeline-view .fc-timeline-event-harness .fc-event-main-frame .fc-event-time {
    display: none;
}

.fc-resourceTimeline-view .event-hover-view {
    width: 350px;
    position: relative;
    /*position: fixed;*/
    /*margin-top: 105px;*/
    z-index: 9;
    background: #ffffff;
    border-radius: 0px 21px 21px 21px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.fc-resourceTimeline-view .event-hover-view .event-hover-view-header {
    background: #F4F4F4;
    padding: 11px 15px 11px 15px;
}

.fc-resourceTimeline-view .event-hover-view .event-hover-view-body {
    padding: 5px 15px 5px 15px;
}

.fc-resourceTimeline-view .event-hover-view p {
    margin: 0px;
    font-size: 10px;
}

.fc-resourceTimeline-view .event-hover-view a {
    color: #2D3748;
}





.fullcalender-event-hover-view {
    width: 350px;
    position: relative;
    /*position: fixed;*/
    /*margin-top: 105px;*/
    z-index: 9;
    background: #ffffff;
    border-radius: 0px 21px 21px 21px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.fullcalender-event-hover-view .event-hover-view-header {
    background: #F4F4F4;
    padding: 5px 15px 5px 15px;
}

.fullcalender-event-hover-view .event-hover-view-body {
    padding: 5px 15px 5px 15px;
}

.fullcalender-event-hover-view p {
    margin: 0px;
    font-size: 10px;
}

.fullcalender-event-hover-view a {
    color: #2D3748;
    font-size: 15px;
}




.invalid-schedule .form-control {
    border-color: #fd5c70 !important;
}

.fc .fc-timeline-overlap-disabled .fc-timeline-lane-frame .fc-timeline-events,
    .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
    z-index: unset;
}

.fc-resourceTimeline-view .event-hover-view-table tr:last-child .event-hover-view {
    top: unset !important;
    bottom: 123px;
}

.fc-resourceTimeline-view .event-hover-view-table tr:nth-last-child(-n + 2) .event-hover-view {
    top: unset !important;
    bottom: 123px;
}

.fc-resourceTimeline-view .fc-timeline-events.fc-scrollgrid-sync-inner {
    max-height: 25px !important;
}

/*
.fc-timeline-lane-frame, .fc-scrollgrid-section-body .fc-datagrid-cell-frame{
    height: 62px!important;
}
*/

/*
.fc-resourceTimeline-view {
    max-height: 545px;
    overflow-y: auto;
}
*/

/*
.fc-resourceTimeline-view > table > tbody > tr > td > .fc-scroller-harness > .fc-scroller > table.fc-datagrid-body.fc-scrollgrid-sync-table{
    width: 300px;
}

.fc-resourceTimeline-view > table > tbody {
    display: block;
    max-height: 545px;
    overflow-y: scroll;
    width: calc(100vw - 50px);
}

@media (min-width: 1200px) {
    .fc-resourceTimeline-view > table > tbody {
        display: block;
        max-height: 545px;
        overflow-y: scroll;
        width: calc(100vw - 340px);
    }
}
*/